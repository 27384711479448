import React, { useEffect, useState, useContext } from 'react'
import {
    VStack,
    Flex,
    Image,
    Text,
    Input,
    Button,
    Link,
    Box,
    useToast
} from '@chakra-ui/react'
import { ConnectButton } from '@rainbow-me/rainbowkit';
import {useNavigate} from 'react-router-dom';
import axios from 'axios';
import { SIGN_IN_URL } from '../../utils/urls';
import { useAccount, useDisconnect } from 'wagmi';
import { AppContext } from '../../provider/AppProvider';
import { useCookies } from 'react-cookie';
import { FaRegUser } from "react-icons/fa";
import { MdLockOutline } from "react-icons/md";

export default function SignIn({change, isMobile}) {
    const toast = useToast();
    const [cookies, setCookie] = useCookies(['token'])
    const { setUser } = useContext(AppContext);
    const { isConnected, address } = useAccount();
    const { disconnect } = useDisconnect()
    const navigate = useNavigate();
    const [data, setData] = useState({
        email: '',
        password: ''
    });

    const handleSignIn = async () => {
        if (data.email === '') {
            toast({
                title: 'Email is required',
                status: 'error',
                position: 'top-right',
                isClosable: true,
            })
            return;
        }
        if (data.password === '') {
            toast({
                title: 'Password is required',
                status: 'error',
                position: 'top-right',
                isClosable: true,
            })
            return;
        }
        try {
            const response = await axios.post(SIGN_IN_URL, {
                ...data,
                wallet: ''
            });
            const {success} = response.data;
            if (success === false) {
                toast({
                    title: 'Failed',
                    description: response.data.message,
                    status: 'error',
                    isClosable: true,
                    position: 'top-right'
                })
                return;
            }
            setUser(response.data)
            setCookie('token', response.data.token, {path: '/'});
            navigate('/assistant');
        } catch (error) {
            console.log(error);
        }
    }

    const handleSignInWithWallet = async () => {
        try {
            const response = await axios.post(SIGN_IN_URL, {
                email: '',
                password: '',
                wallet: address
            });

            const {success} = response.data;
            if (!success) {
                toast({
                    title: 'Failed',
                    description: response.data.message,
                    status: 'error',
                    isClosable: true,
                    position: 'top-right'
                })
                disconnect();
                return;
            }
            setUser(response.data)
            disconnect();
            setCookie('token', response.data.token, {path: '/'});
            navigate('/assistant');
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (isConnected) {
            handleSignInWithWallet();
        }
    }, [isConnected]);

    return (
        <VStack w={isMobile == 'true' ? '' : '30%'}>
            <Flex alignItems={'center'} pb={5}>
                <Image src='./codex.png' width={isMobile == 'true' ? '3.5rem' : '6.5em'} height={isMobile == 'true' ? '3.5rem' : '6.5em'} borderRadius={23} />
                <Text className={isMobile == 'true' ? 'logo-text-mobile' : 'logo-text'}>CODEX</Text>
            </Flex>
            <Text color={'white'} fontWeight={'800'} fontSize={isMobile == 'true' ? '3xl' : '6xl'}>Sign In</Text>
            <VStack alignItems={'start'} w={'full'} px={2} py={isMobile == 'true' ? 4 : 6}>
                <Box display={'flex'} padding={isMobile == 'true' ? '8px 5px' : '14px 8px'} background={'#1e2428'} borderRadius={'12px'} alignItems={'center'} w={'full'}>
                    <Box pl={2}>
                        <FaRegUser color='white' width={'3rem'} fontSize={isMobile == 'true' ? '1rem' : '1.5rem'}/>
                    </Box>
                    <Input placeholder='Enter your email' color={'white'} border={'none'} boxShadow={'none'} fontSize={isMobile == 'true' ? '1rem' : '1.5rem'} _focus={{boxShadow: 'none'}} value={data.email} onChange={e => setData({...data, email: e.target.value})}/>
                </Box>
            </VStack>
            <VStack alignItems={'start'} w={'full'} px={2} py={isMobile == 'true' ? 4 : 6}>
                <Box display={'flex'} padding={isMobile == 'true' ? '8px 5px' : '14px 8px'} background={'#1e2428'} borderRadius={'12px'} alignItems={'center'} w={'full'}>
                    <Box pl={2}>
                        <MdLockOutline color='white' width={'3rem'} fontSize={isMobile == 'true' ? '1rem' : '1.5rem'}/>
                    </Box>
                    <Input placeholder='**************' type='password' color={'white'} border={'none'} boxShadow={'none'} fontSize={isMobile == 'true' ? '1rem' : '1.5rem'} _focus={{boxShadow: 'none'}} value={data.password} onChange={e => setData({...data, password: e.target.value})} />
                </Box>
            </VStack>
            <Box w={'full'} px={2} py={4}>
                <Button colorScheme='blue' w={'full'} fontSize={isMobile == 'true' ? '1rem' : '1.5rem'} h={isMobile == 'true' ? '2.5rem' : '3.5rem'} mt={2} onClick={handleSignIn}>Sign in</Button>
            </Box>
            <Flex w={'full'} alignItems={'start'}>
                <Text color={'white'}>Don't you have an account? &nbsp;</Text>
                <Link color={'blue.400'} onClick={() => change(false)}>Sign up</Link>
            </Flex>
            <Box w={'full'} id='wallet-button' borderTop={'solid 1px'} fontSize={isMobile == 'true' ? '1rem' : '1.2rem'} padding={2} display={'flex'} justifyContent={'center'}>
                <ConnectButton label='Login with Wallet'/>
            </Box>
            {/* <ConnectButton label='Login with Wallet' /> */}
        </VStack>
    )
}
