import {
    WrapItem,
    Box,
    useColorModeValue
} from '@chakra-ui/react'

export default function PriceWrapper({ children }) {
    return (
        <WrapItem justifyContent={'center'} border={'none'} boxShadow={'none'} background={'transparent'}>
            <Box
                mb={4}
                shadow="base"
                background={'transparent'}
                border={'none'}
                boxShadow={'none'}
                // borderWidth="1px"
                alignSelf={{ base: 'center', lg: 'flex-start' }}
                // borderColor={useColorModeValue('gray.200', 'gray.500')}
                // borderRadius={'xl'}
            >
                {children}
            </Box>
        </WrapItem>
    );
}