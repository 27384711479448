import React, { useState } from 'react'
import {
    VStack,
    Flex,
    Image,
    Text,
    Box,
    Input,
    Button,
    Link,
    useToast
} from '@chakra-ui/react'
import { MdOutlineEmail } from "react-icons/md";
import { MdOutlineAccountBalanceWallet } from "react-icons/md";
import { MdLockOutline } from "react-icons/md";
import { validateEmail } from '../../utils/utils';
import axios from 'axios';
import { SIGN_UP_URL } from '../../utils/urls';

export default function SignUp({change, isMobile}) {
    const toast = useToast();
    const [data, setData] = useState({
        email: '',
        password: '',
        wallet: ''
    })

    const handleSignUp = async () => {
        if (data.email === '' && data.wallet === '') {
            toast({
                title: 'Email or Wallet are required',
                status: 'error',
                position: 'top-right',
                isClosable: true,
            })
            return;
        }
        if (data.password === '') {
            toast({
                title: 'Password is required',
                status: 'error',
                position: 'top-right',
                isClosable: true,
            })
            return;
        }
        if (data.email !== '' && !validateEmail(data.email)) {
            toast({
                title: 'Email is invalid',
                status: 'error',
                position: 'top-right',
                isClosable: true,
            })
            return;
        }
        try {
            const response = await axios.post(SIGN_UP_URL, data);
            const {success} = response.data;
            if (!success) {
                toast({
                    title: 'Failed',
                    description: response.data.message,
                    position: 'top-right',
                    isClosable: true,
                    status: 'error'
                })
                return;
            }
            change(true)
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <VStack w={isMobile == 'true' ? '' : '30%'}>
            <Flex alignItems={'center'} pb={5}>
                <Image src='./codex.png' width={isMobile == 'true' ? '3.5rem' : '6.5em'} height={isMobile == 'true' ? '3.5rem' : '6.5em'} borderRadius={23} />
                <Text className={isMobile == 'true' ? 'logo-text-mobile' : 'logo-text'}>CODEX</Text>
            </Flex>
            <Text color={'white'} fontWeight={'800'} fontSize={isMobile == 'true' ? '3xl' : '6xl'}>Sign Up</Text>
            <VStack alignItems={'start'} w={'full'} px={2} py={isMobile == 'true' ? 2 : 4}>
                <Box display={'flex'} padding={isMobile == 'true' ? '8px 5px' : '14px 8px'} background={'#1e2428'} borderRadius={'12px'} alignItems={'center'} w={'full'}>
                    <Box pl={2}>
                        <MdOutlineEmail color='white' width={'3rem'} fontSize={isMobile == 'true' ? '1rem' : '1.5rem'}/>
                    </Box>
                    <Input placeholder='Enter your email' color={'white'} border={'none'} boxShadow={'none'} fontSize={isMobile == 'true' ? '1rem' : '1.5rem'} _focus={{boxShadow: 'none'}} value={data.email} onChange={e => setData({...data, email: e.target.value})} />
                </Box>
            </VStack>
            <VStack alignItems={'start'} w={'full'} px={2} py={4}>
                <Box display={'flex'} padding={isMobile == 'true' ? '8px 5px' : '14px 8px'} background={'#1e2428'} borderRadius={'12px'} alignItems={'center'} w={'full'}>
                    <Box pl={2}>
                        <MdOutlineAccountBalanceWallet color='white' width={'3rem'} fontSize={isMobile == 'true' ? '1rem' : '1.5rem'}/>
                    </Box>
                    <Input placeholder='Enter your wallet address' color={'white'} border={'none'} boxShadow={'none'} fontSize={isMobile == 'true' ? '1rem' : '1.5rem'} _focus={{boxShadow: 'none'}} value={data.wallet} onChange={e => setData({...data, wallet: e.target.value})} />
                </Box>
            </VStack>
            <VStack alignItems={'start'} w={'full'} px={2} py={6}>
                <Box display={'flex'} padding={isMobile == 'true' ? '8px 5px' : '14px 8px'} background={'#1e2428'} borderRadius={'12px'} alignItems={'center'} w={'full'}>
                    <Box pl={2}>
                        <MdLockOutline color='white' width={'3rem'} fontSize={isMobile == 'true' ? '1rem' : '1.5rem'}/>
                    </Box>
                    <Input placeholder='**************' type='password' color={'white'} border={'none'} boxShadow={'none'} fontSize={isMobile == 'true' ? '1rem' : '1.5rem'} _focus={{boxShadow: 'none'}} value={data.password} onChange={e => setData({...data, password: e.target.value})} />
                </Box>
            </VStack>
            <Box w={'full'} px={2} py={4}>
                <Button colorScheme='blue' w={'full'} fontSize={isMobile == 'true' ? '1rem' : '1.5rem'} h={isMobile == 'true' ? '2.5rem' : '3.5rem'} mt={2} onClick={handleSignUp}>Sign up</Button>
            </Box>
            {/* <Button colorScheme='blue' w={'full'} mt={2} onClick={handleSignUp}>Sign up</Button> */}
            <Flex w={'full'} alignItems={'start'}>
                <Text color={'white'}>Do you have already an account? &nbsp;</Text>
                <Link color={'blue.400'} onClick={() => change(true)}>Sign in</Link>
            </Flex>
            {/* <ConnectButton label='Login with Wallet' /> */}
        </VStack>
    )
}
