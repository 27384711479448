import React, { useContext } from 'react'
import {
    Card,
    CardBody,
    Image,
    Divider,
    CardFooter,
    WrapItem,
    Box,
    Badge,
    AlertDialog,
    AlertDialogBody,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogFooter,
    Button,
    useDisclosure,
    useToast
} from '@chakra-ui/react';
import { CURRENT_CHAT_SAVE_URL, DELETE_ASSISTANTS_URL, SERVER_URL } from '../../utils/urls';
import { SmallCloseIcon } from '@chakra-ui/icons';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { AppContext } from '../../provider/AppProvider';
import { useNavigate } from 'react-router-dom';

export default function AssistantItem({ mine, get, item }) {
    const toast = useToast();
    const navigate = useNavigate();
    const [cookies] = useCookies(['token'])
    const { setCurrentAssistant, user, messages, setMessages, currentAssistant, a_flag, setA_Flag } = useContext(AppContext);
    const { isOpen, onOpen, onClose } = useDisclosure();

    const handleDeleteItem = async () => {
        if (cookies.token === undefined) {
            return;
        }
        try {
            await axios.post(DELETE_ASSISTANTS_URL, { id: item._id }, {
                headers: {
                    Authorization: `Bearer ${cookies.token}`
                }
            });
            get();
            onClose();
        } catch (error) {
            console.log(error);
        }
    }

    const handleSaveChat = async () => {
        if (cookies.token === undefined) {
            return;
        }
        try {
            await axios.post(
                CURRENT_CHAT_SAVE_URL,
                {
                    messages,
                    name: item.name,
                    img: item.img,
                    content: item.content,
                    h_name: item.name + ' ' + new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString()
                },
                {
                    headers: {
                        Authorization: `Bearer ${cookies.token}`
                    }
                }
            );
        } catch (error) {
            console.log(error);
        }
    }

    const handleSelectAssistant = () => {
        if (item.pro === true && user.subscription.plan === 'free' && user.admin === false) {
            return toast({
                title: 'Error',
                description: "To use it or other PRO chat modes, you need to have an active Subscription",
                status: 'error',
                isClosable: true,
                position: 'top-right'
            })
        }
        if (currentAssistant.name !== item.name && messages.length > 1) {
            // save chat
            handleSaveChat();
            setMessages([{
                message: `Hello, I am ${item.name}! Ask me anything!`,
                sentTime: new Date().toLocaleTimeString(),
                sentDate: new Date().toLocaleDateString(),
                sender: "ChatGPT",
            }])
            setA_Flag(!a_flag);
        }
        setCurrentAssistant({
            name: item.name,
            img: item.img,
            content: item.content + item.intel_text + item.intel_docs,
            temperature: item.temperature,
            max_tokens: item.max_tokens,
            enable_ques: item.enable_ques,
            questions: item.questions
        });
        navigate('/');
    }

    return (
        <WrapItem>
            <Card maxW={'10rem'} borderRadius={30}>
                <CardBody w={'10rem'} h={'8.5rem'} position={'relative'}>
                    <Image
                        w={'8.5rem'}
                        h={'7.5rem'}
                        src={SERVER_URL + item.img}
                        alt='Green double couch with wooden legs'
                        borderRadius='lg'
                        cursor={'pointer'}
                        onClick={handleSelectAssistant}
                    />
                    {(mine || user.admin) && <Badge zIndex={100} cursor={'pointer'} colorScheme='red' borderRadius={'50%'} justifyContent={'center'} w={'1.2rem'} h={'1.2rem'} position={'absolute'} right={0} top={0} fontSize={'1.2rem'} padding={0} onClick={onOpen}>
                        <SmallCloseIcon position={'absolute'} left={0} top={0} />
                    </Badge>}
                </CardBody>
                <Divider />
                <CardFooter justifyContent={'center'} padding={'0.3rem 1rem'}>
                    <Box textOverflow={'ellipsis'} overflow={'hidden'} whiteSpace={'nowrap'}>
                        {
                            item.name
                        }
                    </Box>
                </CardFooter>
            </Card>
            <AlertDialog
                isOpen={isOpen}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Delete Assistant
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Are you sure? You can't undo this action afterwards.
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button colorScheme='red' onClick={() => handleDeleteItem()} mr={3}>
                                Delete
                            </Button>
                            <Button onClick={onClose}>
                                Cancel
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </WrapItem>
    )
}
