import './App.css';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import Home from './components/Home';
import Login from './components/Login';
import axios from 'axios';
import Buy from './components/Buy/Buy';
import Assistants from './components/Assistants/Assistants';
import { CookiesProvider } from 'react-cookie';

import '@rainbow-me/rainbowkit/styles.css';
import {
  getDefaultConfig,
  RainbowKitProvider,
} from '@rainbow-me/rainbowkit';
import { WagmiProvider } from 'wagmi';
import {
  mainnet,
  polygon,
  optimism,
  arbitrum,
  base,
} from 'wagmi/chains';
import {
  QueryClientProvider,
  QueryClient,
} from "@tanstack/react-query";
import AppProvider from './provider/AppProvider';

const config = getDefaultConfig({
  appName: 'My RainbowKit App',
  projectId: 'YOUR_PROJECT_ID',
  chains: [mainnet, polygon, optimism, arbitrum, base],
  ssr: false,
});

function App() {
  axios.defaults.headers.common['header'] = 'application/json';
  const queryClient = new QueryClient();
  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider>
          <CookiesProvider>
            <AppProvider>
              <div className="App">
                <Router>
                  <Routes>
                    <Route path='/login' element={<Login />} />
                    <Route path='/' element={<Home />} />
                    <Route path='/buy' element={<Buy />} />
                    <Route path='/assistant' element={<Assistants />} />
                    {/* <Route path='/price' element={<Crypto />} />
                    <Route path='/history' element={<History />} />
                    <Route path='/login' element={<Login />} />
                    <Route path='/statistics' element={<Statistic />} /> */}
                  </Routes>
                </Router>
              </div>
            </AppProvider>
          </CookiesProvider>
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
}

export default App;
