import React, { useContext, useEffect, useState } from 'react'
import {
    Box,
    Flex,
    Text,
    CloseButton,
    Image,
    VStack,
    Divider,
    useColorModeValue,
    useDisclosure
} from '@chakra-ui/react'
import {
    FiSettings
} from 'react-icons/fi'
import { NavItem } from './NavItem'
import { BiPlus } from "react-icons/bi";
import { FaHeadSideVirus } from "react-icons/fa6";
import { IoWalletOutline } from "react-icons/io5";
import Settings from '../Setting/Settings';
import { Link } from 'react-router-dom';
import {useNavigate} from 'react-router-dom';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import { CURRENT_CHAT_SAVE_URL, GET_CHAT_HISTORY_LIST_URL } from '../../utils/urls';
import { ChatHistory } from './ChatHistory';
import { RiHistoryFill } from "react-icons/ri";
import { AppContext } from '../../provider/AppProvider';

export const SidebarContent = ({ onClose, ...rest }) => {
    const {isOpen, onOpen, onClose: onSettingClose} = useDisclosure();
    const [ cookies ] = useCookies(['token']);
    const { currentAssistant, messages, setMessages, a_flag } = useContext(AppContext);
    const [chatList, setChatList] = useState([]);
    const navigate = useNavigate();

    const handleNewChat = () => {
        handleSaveChat();
        navigate('/')
    }

    const handleSaveChat = async () => {
        if (cookies.token === 'undefined') {
            return;
        }

        try {
            await axios.post(
                CURRENT_CHAT_SAVE_URL,
                {
                    messages,
                    name: currentAssistant.name,
                    img: currentAssistant.img,
                    content: currentAssistant.content,
                    h_name: currentAssistant.name + ' ' + new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString()
                },
                {
                    headers: {
                        Authorization: `Bearer ${cookies.token}`
                    }
                }
            );
            setMessages([{
                message: `Hello, I am ${currentAssistant.name}! Ask me anything!`,
                sentTime: new Date().toLocaleTimeString(),
                sentDate: new Date().toLocaleDateString(),
                sender: "ChatGPT",
            }])
            getChatList();
        } catch (error) {
            console.log(error);
        }
    }

    const getChatList = async () => {
        if (cookies.token === undefined) {
            return;
        }

        try {
            const response = await axios.get(GET_CHAT_HISTORY_LIST_URL, {
                headers: {
                    Authorization: `Bearer ${cookies.token}`
                }
            });
            setChatList(response.data);
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getChatList();
    }, [currentAssistant, a_flag]);

    return (
        <Box
            transition="3s ease"
            bg={useColorModeValue('white', 'gray.900')}
            borderRight="1px"
            borderRightColor={useColorModeValue('gray.200', 'gray.700')}
            w={{ base: 'full', md: 60 }}
            pos="fixed"
            h="full"
            {...rest}>
            <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
                <Flex width={"100%"} alignItems={'center'} py={8}>
                    <Image src='./codex.png' width={'3.5em'} height={'3.5em'} borderRadius={'50%'} />
                    <VStack gap={0}>
                        <Text className='logo-text' style={{fontSize: '1.4rem'}}>CODEX</Text>
                        <Text className='logo-text' style={{fontSize: '1rem'}}>ChatAI</Text>
                    </VStack>
                </Flex>
                <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
            </Flex>
            <NavItem icon={BiPlus} onClick={handleNewChat}>New Chat</NavItem>
            <Link to={'/assistant'}>
                <NavItem icon={FaHeadSideVirus}>Assistants</NavItem>
            </Link>
            <Link to={'/buy'}>
                <NavItem icon={IoWalletOutline}>Buy</NavItem>
            </Link>
            <NavItem icon={FiSettings} onClick={onOpen}>Settings</NavItem>
            {/* {LinkItems.map((link) => (
                <NavItem key={link.name} icon={link.icon}>
                    {link.name}
                </NavItem>
            ))} */}
            <VStack w={"96%"} py={4} alignItems={'start'}>
                <Text pl={2} fontSize={'sm'} alignItems={'center'} display={'flex'}><RiHistoryFill />&nbsp;History</Text>
                <Divider />
            </VStack>
            <VStack overflow={'auto'} maxHeight={'67%'}>
                {
                    chatList.map((item) => {
                        return <ChatHistory key={item._id} item={item} get={getChatList}>{item.h_name}</ChatHistory>
                    })
                }
            </VStack>
            <Settings isOpen={isOpen} onClose={onSettingClose} />
        </Box>
    )
}