import React, { useState } from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Box,
    useRadio,
    useRadioGroup,
    VStack,
    Flex,
    Divider,
} from '@chakra-ui/react'
// import AssistantList from './AssistantList'
import ChatList from './ChatList'
import Language from './Language'

function RadioCard(props) {
    const { getInputProps, getRadioProps } = useRadio(props)
  
    const input = getInputProps()
    const checkbox = getRadioProps()
  
    return (
      <Box as='label' w={'100%'}>
        <input {...input} />
        <Box
            {...checkbox}
            cursor='pointer'
            borderWidth='1px'
            borderRadius='md'
            variant="ghost"
            _checked={{
                // color: 'black',
                borderColor: 'teal.600',
            }}
            px={5}
            py={1}
        >
          {props.children}
        </Box>
      </Box>
    )
}

export default function Settings({isOpen, onClose}) {
    const [tag, setTag] = useState('Archieve');
    const options = ['Archieve', 'Language']

    const { getRootProps, getRadioProps } = useRadioGroup({
        name: 'framework',
        defaultValue: 'Chat',
        onChange: e => setTag(e),
    })

    const group = getRootProps()

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'4xl'}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Setting</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Flex w={'100%'} justifyContent={'space-between'}>
                        <VStack {...group} w={"20%"}>
                            {options.map((value) => {
                                const radio = getRadioProps({ value })
                                return (
                                    <RadioCard key={value} {...radio}>
                                        {value}
                                    </RadioCard>
                                )
                            })}
                        </VStack>
                        <Divider orientation='vertical'/>
                        <VStack w={'75%'}>
                            {/* {tag === options[0] && <AssistantList data={[]} />} */}
                            {tag === options[0] && <ChatList onClose={onClose} />}
                            {tag === options[1] && <Language />}
                        </VStack>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}
