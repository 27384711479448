// export const SERVER_URL = 'http://192.168.136.108:5000';
export const SERVER_URL = 'https://api.codextoken.com';
export const API_URL = SERVER_URL + '/api';

export const SIGN_IN_URL = API_URL + '/user/signin';
export const SIGN_UP_URL = API_URL + '/user/signup';
export const GET_USER_INFO_URL = API_URL + '/user/info';
export const CHANGE_USER_MODEL_URL = API_URL + '/user/model';
export const CHANGE_USER_LANGUAGE_URL = API_URL + '/user/lang';
export const CHANGE_USER_INFO_URL = API_URL + '/user/update';
export const GET_DAPP_INFO_URL = API_URL + '/user/dapp';
export const CONSUME_TOKEN_URL = API_URL + '/user/token';

export const GET_ASSISTANTS_URL = API_URL + '/get/prompts';
export const CREATE_ASSISTANTS_URL = API_URL + '/set/prompts';
export const DELETE_ASSISTANTS_URL = API_URL + '/delete/mine/prompt';

export const CHECKOUT_TOKEN_URL = API_URL + '/crypto/checkout';
export const CHECKOUT_SUBSCRIPTION_URL = API_URL + '/crypto/checkout/subscription';

export const BITTENSOR_API_URL = API_URL + '/bittensor';

export const IMAGE_GENERATE_URL = API_URL + '/image/generate';

export const CURRENT_CHAT_SAVE_URL = API_URL + '/chat/save';
export const GET_CHAT_HISTORY_LIST_URL = API_URL + '/chat/list';
export const GET_ARCHIEVE_CHAT_HISTORY_LIST_URL = API_URL + '/chat/get/archieve';
export const DELETE_CHAT_HISTORY_URL = API_URL + '/chat/delete';
export const GET_CHAT_CONTENT_URL = API_URL + '/chat/content';
export const MOVE_ARCHIEVE_CHAT_HISTORY_URL = API_URL + '/chat/archieve';

export const GET_CHAT_REPONSE_URL = API_URL + '/get/chat';

export const CHATGPT_URL = "https://api.openai.com/v1/chat/completions";
export const BITTENSOR_URL = "https://api.corcel.io/v1/text/cortext/chat";