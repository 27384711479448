import React, { useContext } from 'react'
import {
  Grid,
  GridItem,
  Box,
  useRadio,
  useRadioGroup
} from '@chakra-ui/react'
import { AppContext } from '../../provider/AppProvider'
import axios from 'axios';
import { CHANGE_USER_LANGUAGE_URL } from '../../utils/urls';
import { useCookies } from 'react-cookie';

const RadioLanguageCard = (props) => {
  const { getInputProps, getRadioProps } = useRadio(props)

  const input = getInputProps()
  const checkbox = getRadioProps()

  return (
    <GridItem as='label'>
      <input {...input} />
      <Box
        {...checkbox}
        cursor='pointer'
        borderWidth='1px'
        borderRadius='md'
        boxShadow='md'
        _checked={{
          bg: 'teal.600',
          color: 'white',
          borderColor: 'teal.600',
        }}
        _focus={{
          boxShadow: 'outline',
        }}
        px={5}
        py={3}
      >
        {props.children}
      </Box>
    </GridItem>
  )
}

export default function Language() {
  const { user, setUser } = useContext(AppContext);
  const [cookies] = useCookies(['token']);

  const languages = ['English', 'Русский', 'Español', 'Türkçe', 'Bahasa Indonesia', 'Português', 'Français', 'Italiano', 'Deutsch', 'Українська', 'زبان تغییر یافت به', 'हिन्दी', 'العربية', '中文'];

  const { getRadioProps: getLanguageProps, getRootProps: getLanguageRootProps } = useRadioGroup({
    name: 'language',
    defaultValue: user.lang,
    onChange: e => handleChangeLang(e)
  })
  const groupLanguage = getLanguageRootProps();
  const handleChangeLang = async (val) => {
    if (cookies.token === 'undefined') {
      return;
  }

    try {
      await axios.post(CHANGE_USER_LANGUAGE_URL, {lang: val}, {
        headers: {
          Authorization: `Bearer ${cookies.token}`
        }
      });
      setUser({...user, lang: val})
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Grid {...groupLanguage} templateColumns='repeat(2, 1fr)' gap={6}>
      {languages.map((value) => {
        const radio = getLanguageProps({ value })
        return (
          <RadioLanguageCard key={value} {...radio}>
            {value}
          </RadioLanguageCard>
        )
      })}
    </Grid>
  )
}
