import React, { useEffect, useState } from 'react'
import {
    Flex,
    Stat,
    Box,
    StatLabel,
    StatNumber,
    useColorModeValue
} from '@chakra-ui/react'
import { BsPerson } from 'react-icons/bs';
import { BiPackage } from 'react-icons/bi';
import { AiOutlineFileProtect } from 'react-icons/ai';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { GET_DAPP_INFO_URL } from '../../utils/urls';

const StatsCard = ({ title, stat, icon }) => {
    return (
        <Stat
            px={{ base: 2, md: 4 }}
            py={'5'}
            my={2}
            mx={2}
            shadow={'xl'}
            border={'1px solid'}
            borderColor={'gray'}
            rounded={'lg'}
        >
            <Flex justifyContent={'space-between'}>
                <Box pl={{ base: 2, md: 4 }}>
                    <StatLabel fontWeight={'medium'} isTruncated>
                        {title}
                    </StatLabel>
                    <StatNumber fontSize={'2xl'} fontWeight={'medium'}>
                        {stat}
                    </StatNumber>
                </Box>
                <Box
                    my={'auto'}
                    color={useColorModeValue('gray.800', 'gray.200')}
                    alignContent={'center'}
                >
                    {icon}
                </Box>
            </Flex>
        </Stat>
    )
}

export default function UserInfo() {
    const [cookies] = useCookies(['token']);
    const [info, setInfo] = useState({
        users: 0,
        token: 0,
        sub_weekly: 0,
        sub_monthly: 0,
        sub_yearly: 0
    })

    const getUserInfo = async () => {
        if (cookies.token === 'undefined') {
            return;
        }

        try {
            const response = await axios.get(GET_DAPP_INFO_URL, {
                headers: {
                    Authorization: `Bearer ${cookies.token}`
                }
            });
            const { users, buy } = response.data;
            setInfo({
                users: users,
                month_1: buy.subscription_month_1,
                month_3: buy.subscription_month_3,
                month_6: buy.subscription_month_6,
                month_12: buy.subscription_month_12
            });
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getUserInfo();
    }, []);

    return (
        <Box>
            <StatsCard
                title={'Users'}
                stat={info.users}
                icon={<BsPerson size={'3em'} />}
            />
            <Flex>
                <StatsCard
                    title={'1 Month'}
                    stat={info.month_1}
                    icon={<BiPackage size={'3em'} />}
                />
                <StatsCard
                    title={'3 Month'}
                    stat={info.month_3}
                    icon={<AiOutlineFileProtect size={'3em'} />}
                />
            </Flex>
            <Flex>
                <StatsCard
                    title={'6 Month'}
                    stat={info.month_6}
                    icon={<AiOutlineFileProtect size={'3em'} />}
                />
                <StatsCard
                    title={'12 Month'}
                    stat={info.month_12}
                    icon={<AiOutlineFileProtect size={'3em'} />}
                />
            </Flex>
        </Box>
    )
}
