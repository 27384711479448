import React from 'react';
import {
    Modal,
    ModalBody,
    ModalOverlay,
    ModalHeader,
    ModalCloseButton,
    ModalContent,
    Divider,
    Image,
} from '@chakra-ui/react';

export const ImageModal = ({isOpen, onClose, src}) => {
    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            size={'xl'}
        >
            <ModalOverlay />
            <ModalContent minH={500}>
                <ModalHeader>Generated Image</ModalHeader>
                <ModalCloseButton />
                <Divider />
                <ModalBody>
                    <Image w={'100%'} h={'100%'} src={src} />
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}