import {
    Button,
    useColorMode
} from '@chakra-ui/react'
import { FaSun } from "react-icons/fa";
import { FaMoon } from "react-icons/fa";

export default function ColorModeButton() {
    const { colorMode, toggleColorMode } = useColorMode()
    return (
        <Button onClick={toggleColorMode} variant={'ghost'} px={2}>
            {colorMode === 'light' ? <FaMoon /> : <FaSun />}
        </Button>
    )
  }