import React, { useState } from 'react'
import {
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalOverlay,
    ModalHeader,
    ModalContent,
    VStack,
    Text,
    Input,
    Textarea,
    Button,
    Flex,
    useToast,
    Tab,
    TabList,
    Tabs,
    TabPanel,
    TabPanels,
    RadioGroup,
    Radio,
    Stack,
    useColorMode,
    UnorderedList,
    ListItem,
    Switch,
    OrderedList,
    ButtonGroup
} from '@chakra-ui/react'
import { FileUpload } from 'primereact/fileupload'
import 'react-image-upload/dist/index.css'
import "primereact/resources/themes/lara-dark-teal/theme.css";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin5Line } from "react-icons/ri";
import axios from 'axios';
import { CREATE_ASSISTANTS_URL } from '../../utils/urls';
import { useCookies } from 'react-cookie';

export default function CreateAssistant({ isOpen, onClose }) {
    const { colorMode } = useColorMode();
    const toast = useToast();
    const [cookies] = useCookies(['token']);
    const [data, setData] = useState({
        img: null,
        name: '',
        content: '',
        temperature: 0.2,
        max_tokens: 100,
        llm: 'smartest',
        enable_ques: false,
        questions: [],
        intel_text: '',
        intel_docs: null
    })
    const [question, setQuestion] = useState('');
    const [edit, setEdit] = useState(-1);
    const [tabIndex, setTabIndex] = useState(0);
    const handleFileUpload = (e) => {
        setData({
            ...data,
            img: e.files[0]
        })
    }

    const handleIntelFileUpload = e => {
        if (e.files[0] === null) return;
        const fileReader = new FileReader();
        fileReader.onloadend = handleFileRead;
        fileReader.readAsText(e.files[0]);
    }

    const handleFileRead = async (e) => {
        const content = e.target.result;
        setData({
            ...data,
            intel_docs: content
        });
    };

    const handleCreateAssistant = async () => {
        if (data.name === '') {
            toast({
                title: 'Name is required.',
                status: 'error',
                isClosable: true,
                position: 'top-right'
            })
            return;
        }
        if (data.content === '') {
            toast({
                title: 'Prompt is required.',
                status: 'error',
                isClosable: true,
                position: 'top-right'
            })
            return;
        }
        if (data.img === null) {
            toast({
                title: 'Image is required.',
                status: 'error',
                isClosable: true,
                position: 'top-right'
            })
            return;
        }
        const formData = new FormData();
        formData.append('file', data.img);
        formData.append('data', JSON.stringify(data));
        if (cookies.token === undefined) {
            return;
        }
        try {
            await axios.post(CREATE_ASSISTANTS_URL, formData, {
                headers: {
                    Authorization: `Bearer ${cookies.token}`
                }
            })
            setData({ 
                name: '', 
                content: '', 
                img: null,  
                temperature: 0.2,
                max_tokens: 100,
                llm: 'smartest',
                enable_ques: false,
                questions: [],
                intel_text: '',
                intel_docs: null
            });
            setTabIndex(0);
            onClose();
        } catch (error) {
            console.log(error);
        }
    }
    const handleChangeLLM = (e) => {
        if (e === 'smartest') {
            setData({
                ...data,
                llm: 'smartest',
                temperature: 0.2,
                max_tokens: 100
            })
        }
        else {
            setData({
                ...data,
                llm: 'fastest',
                temperature: 0.8,
                max_tokens: 50
            })
        }
    }

    const handleAddQuestion = () => {
        if (edit >= 0) {
            const newQuestions = data.questions.map((item, index) => {
                return index === edit ? question : item
            });
            setData({
                ...data,
                questions: newQuestions
            })
            setQuestion('')
            setEdit(-1);
        }
        if (data.questions.length === 3 || question === '') {
            return;
        }
        if (edit < 0) {
            setData({
                ...data,
                questions: [...data.questions, question]
            })
        }
        setQuestion('');
    }

    const handleDeleteQuestion = (index) => {
        const newQuestions = data.questions.filter((item, idx) => idx !== index);
        setData({
            ...data,
            questions: newQuestions
        })
    }

    const handleEditQuestion = (index) => {
        if (data.questions[index] == null) return;
        setEdit(index);
        setQuestion(data.questions[index]);
    }

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            size={'xl'}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Create Assistant</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Tabs index={tabIndex}>
                        <TabList>
                            <Tab w={'33%'}>Basic *</Tab>
                            <Tab w={'33%'}>Intelligence</Tab>
                            <Tab w={'33%'}>Starter Questions</Tab>
                        </TabList>

                        <TabPanels>
                            <TabPanel>
                                <VStack>
                                    <Text alignSelf={'start'}>Assistant Name:</Text>
                                    <Input placeholder='Enter the name.' value={data.name} onChange={e => setData({ ...data, name: e.target.value })} />
                                    <Flex alignItems={'center'} position={'relative'} w={'full'}>
                                        <Flex alignItems={'center'} w={'50%'} pr={'1rem'} justifyContent={'space-between'}>
                                            <Text left={0}>Image: </Text>
                                            <FileUpload mode="basic" chooseLabel='Choose File' name="demo[]" accept="image/*" maxFileSize={10000000} onSelect={e => handleFileUpload(e)} />
                                        </Flex>
                                        <Flex w={'50%'} justifyContent={'space-between'}>
                                            <Text>LLM:</Text>
                                            <RadioGroup value={data.llm} onChange={e => handleChangeLLM(e)}>
                                                <Stack direction='row'>
                                                    <Radio value='smartest'>Smartest</Radio>
                                                    <Radio value='fastest'>Fastest</Radio>
                                                </Stack>
                                            </RadioGroup>
                                        </Flex>
                                    </Flex>
                                    <Textarea placeholder='e.g. I am a software developer and only use python for data analysis.' value={data.content} onChange={e => setData({ ...data, content: e.target.value })} />
                                    <Text fontSize={'sm'}>Describe your AI bot’s tone, behavior, character traits, its back-story, purpose, name, style, etc. Tell it what to avoid doing/saying. Be as creative as you want!</Text>
                                    <Button onClick={() => setTabIndex(1)}>Next</Button>
                                </VStack>
                            </TabPanel>
                            <TabPanel>
                                <Flex>
                                    <Text display={'flex'} margin={'auto'} fontSize={'lg'}>Custom Knowledge</Text>
                                </Flex>
                                <Flex mb={4}>
                                    <Text margin={'auto'} fontSize={'sm'}>Train your AI bot on custom knowledge/data.</Text>
                                </Flex>
                                <Tabs variant='soft-rounded' colorScheme='green'>
                                    <TabList>
                                        <Tab w={'50%'}>Documents</Tab>
                                        <Tab w={'50%'}>Text</Tab>
                                    </TabList>
                                    <TabPanels>
                                        <TabPanel>
                                            <div className="card">
                                                <FileUpload className={colorMode === 'light' ? 'light-file' : 'dark-file'} mode='basic' name="demo[]" accept="text/*" maxFileSize={1000000} onSelect={e => handleIntelFileUpload(e)} />
                                            </div>
                                        </TabPanel>
                                        <TabPanel>
                                            <Textarea value={data.intel_text} onChange={e => setData({...data, intel_text: e.target.value})}/>
                                        </TabPanel>
                                        <Flex>
                                            <Button mt={2} display={'flex'} mx={'auto'} onClick={() => setTabIndex(0)}>Previous</Button>
                                            <Button mt={2} display={'flex'} mx={'auto'} onClick={() => setTabIndex(2)}>Next</Button>
                                        </Flex>
                                    </TabPanels>
                                </Tabs>
                            </TabPanel>
                            <TabPanel>
                                <Flex>
                                    <Text display={'flex'} margin={'auto'} fontSize={'lg'}>Starter Questions</Text>
                                </Flex>
                                <Flex mb={4}>
                                    <Text margin={'auto'} fontSize={'sm'}>Customize your bot’s initial questions to better serve your users’ needs.</Text>
                                </Flex>
                                <Flex>
                                    <Text display={'flex'} margin={'auto'} fontSize={'lg'}>IMPORTANT</Text>
                                </Flex>
                                <Flex mb={4} justifyContent={'center'}>
                                    <UnorderedList>
                                        <ListItem>
                                            <Text margin={'auto'} fontSize={'sm'}>If you do not specify a response for a particular question, there is no need to worry.</Text>
                                        </ListItem>
                                        <ListItem>
                                            <Text margin={'auto'} fontSize={'sm'}>The bot will automatically provide an answer using the knowledge and AI it possesses.</Text>
                                        </ListItem>
                                        <ListItem>
                                            <Text margin={'auto'} fontSize={'sm'}>You only can have a maximum of 3 starter questions.</Text>
                                        </ListItem>
                                    </UnorderedList>
                                </Flex>
                                <Flex justifyContent={'center'}>
                                    <Switch colorScheme='teal' size='lg' isChecked={data.enable_ques} onChange={e => setData({...data, enable_ques: e.target.checked})}  />
                                    <Text>&nbsp;&nbsp;Enable Starter Questions</Text>
                                </Flex>
                                {
                                    data.enable_ques && <VStack>
                                        <Flex mt={4} w={'full'}>
                                            <Input mr={2} value={question} onChange={e => setQuestion(e.target.value)} />
                                            <Button onClick={handleAddQuestion}>Add</Button>
                                        </Flex>
                                        <OrderedList w={'full'} px={2}>
                                            {
                                                data.questions.map((item, index) => {
                                                    return <ListItem key={'ordered_list_'+index}>
                                                    <Flex alignItems={'center'} justifyContent={'space-between'}>
                                                        <Text overflow={'hidden'} textOverflow={'ellipsis'} whiteSpace={'nowrap'}>
                                                            {item}
                                                        </Text>
                                                        <ButtonGroup>
                                                            <Button variant={'ghost'} onClick={() => handleEditQuestion(index)}><FaRegEdit /></Button>
                                                            <Button variant={'ghost'} onClick={() => handleDeleteQuestion(index)}><RiDeleteBin5Line /></Button>
                                                        </ButtonGroup>
                                                    </Flex>
                                                </ListItem>
                                                })
                                            }
                                            
                                        </OrderedList>
                                    </VStack>
                                }
                                <Flex>
                                    <Button mt={2} display={'flex'} mx={'auto'} onClick={() => setTabIndex(1)}>Previous</Button>
                                    <Button mt={2} display={'flex'} mx={'auto'} onClick={handleCreateAssistant}>Save</Button>
                                </Flex>
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}
