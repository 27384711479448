import React, { useContext } from "react";
import { Box, Flex, Menu, MenuButton, MenuList, MenuItem, IconButton } from "@chakra-ui/react";
import { BsThreeDots } from "react-icons/bs";
import { BiStore } from "react-icons/bi";
import { RiDeleteBin5Line } from "react-icons/ri";
import axios from 'axios';
import { useCookies } from "react-cookie";
import { DELETE_CHAT_HISTORY_URL, MOVE_ARCHIEVE_CHAT_HISTORY_URL } from "../../utils/urls";
import { AppContext } from "../../provider/AppProvider";
import { useNavigate } from "react-router-dom";

export const ChatHistory = ({ item, get, children, ...rest }) => {
    const [cookies] = useCookies(['token']);
    const navigate = useNavigate();
    const { setCurrentAssistant, setMessages } = useContext(AppContext);
    const handleArchieveChat = async () => {
        try {
            await axios.post(MOVE_ARCHIEVE_CHAT_HISTORY_URL, {id: item._id}, {
                headers: {
                    Authorization: `Bearer ${cookies.token}`
                }
            });
            get();
        } catch (error) {
            console.log(error);
        }
    }

    const handleDeleteChat = async () => {
        try {
            await axios.post(DELETE_CHAT_HISTORY_URL, {id: item._id}, {
                headers: {
                    Authorization: `Bearer ${cookies.token}`
                }
            });
            get();
        } catch (error) {
            console.log(error);
        }
    }

    const handleSelectHistory = () => {
        setCurrentAssistant({
            name: item.name,
            img: item.img,
            content: item.content
        });
        setMessages(item.messages)
        navigate('/');
    }

    return (
        <Box
            width={"100%"}
            as="a"
            href="#"
            style={{ textDecoration: "none" }}
            _focus={{ boxShadow: "none" }}
        >
            <Flex
                align="center"
                p="1"
                mx="6"
                borderRadius="lg"
                role="group"
                cursor="pointer"
                justifyContent={"space-between"}
                border={"solid 1px transparent"}
                _hover={{
                    // bg: 'cyan.400',
                    border: "solid 1px #00000033",
                    // color: "white",
                }}
                {...rest}
                onClick={handleSelectHistory}
            >
                <Box
                    ml={2}
                    pr={1}
                    textOverflow={"ellipsis"}
                    overflow={"hidden"}
                    whiteSpace={"nowrap"}
                >
                    {children}
                </Box>
                <Menu>
                    <MenuButton
                        as={IconButton}
                        aria-label="Options"
                        icon={<BsThreeDots />}
                        background={'transparent'}
                    />
                    <MenuList>
                        <MenuItem zIndex={20} icon={<BiStore fontSize={18} />} onClick={handleArchieveChat}>Archieve</MenuItem>
                        <MenuItem icon={<RiDeleteBin5Line fontSize={18} />} onClick={handleDeleteChat} >Delete</MenuItem>
                    </MenuList>
                </Menu>
            </Flex>
        </Box>
    );
};
