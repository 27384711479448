import React, { useEffect, useState } from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Box,
    useRadio,
    useRadioGroup,
    VStack,
    Flex,
    Divider,
    CircularProgress,
    CircularProgressLabel
} from '@chakra-ui/react'
import ProfileInfo from './ProfileInfo'
import UserInfo from './UserInfo'
import axios from 'axios';
import { GET_USER_INFO_URL } from '../../utils/urls';
import { useCookies } from 'react-cookie';

function RadioCard(props) {
    const { getInputProps, getRadioProps } = useRadio(props)
  
    const input = getInputProps()
    const checkbox = getRadioProps()
  
    return (
      <Box as='label' w={'100%'}>
        <input {...input} />
        <Box
            {...checkbox}
            cursor='pointer'
            borderWidth='1px'
            borderRadius='md'
            variant="ghost"
            _checked={{
                // color: 'black',
                borderColor: 'teal.600',
            }}
            px={5}
            py={1}
        >
          {props.children}
        </Box>
      </Box>
    )
}

export default function Profile({isOpen, onClose}) {
    const [tag, setTag] = useState('Profile');
    const options = ['Profile', 'Info', 'Time Remaining']
    const [cookies] = useCookies(['token']);
    const [remain, setRemain] = useState({
        value: 0,
        day: 0,
        hour: 0,
        min: 0
    })

    const getInfo = async () => {
        if (cookies.token === 'undefined') {
            return;
        }

        try {
            const response = await axios.post(GET_USER_INFO_URL, {message: ''}, {
                headers: {
                    Authorization: `Bearer ${cookies.token}`
                }
            });

            let until = new Date(response.data.subscription.buyDate);
            switch (response.data.subscription.plan) {
                case "free":
                    setRemain({
                        day: 0,
                        hour: 0,
                        min: 0,
                        value: 0
                    })
                    return;
                case "weekly":
                    until.setDate(until.getDate() + 7);
                    break;
                case "monthly":
                    until.setMonth(until.getMonth() + 1);
                    break;
                case "yearly":
                    until.setFullYear(until.getFullYear() + 1);
                    break;
                default:
                    break;
            }
            setRemain({
                ...remain,
                total: until.getTime() - new Date(response.data.subscription.buyDate).getTime()
            })

            let diff = until.getTime() - new Date().getTime();

            setRemain({
                day: Math.floor(diff / (24 * 3600 * 1000)),
                hour: Math.floor((diff % (24 * 3600 * 1000)) / (3600 * 1000)),
                min: Math.floor(((diff % (24 * 3600 * 1000)) % (3600 * 1000)) / (60 * 1000)),
                value: 100 - Math.floor(diff * 100 / (until.getTime() - new Date(response.data.subscription.buyDate).getTime()))
            })
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (tag === options[2]) {
            getInfo();
        }
    }, [tag]);

    const { getRootProps, getRadioProps } = useRadioGroup({
        name: 'framework',
        defaultValue: 'react',
        onChange: e => setTag(e),
    })

    const group = getRootProps()

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'4xl'}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Profile</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Flex w={'100%'} justifyContent={'space-between'}>
                        <VStack {...group} w={"20%"}>
                            {options.map((value) => {
                                const radio = getRadioProps({ value })
                                return (
                                    <RadioCard key={value} {...radio}>
                                        {value}
                                    </RadioCard>
                                )
                            })}
                        </VStack>
                        <Divider orientation='vertical'/>
                        <VStack w={'75%'}>
                            {tag === options[0] && <ProfileInfo />}
                            {tag === options[1] && <UserInfo />}
                            {tag === options[2] && <CircularProgress value={remain.value} color='green.400' size={'sm'} thickness={'5px'}>
                                    <CircularProgressLabel fontSize={'4xl'}>
                                        {remain.day}d {remain.hour}h {remain.min}m
                                    </CircularProgressLabel>
                                </CircularProgress>}
                        </VStack>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}
