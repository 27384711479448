import React, { useContext, useEffect, useState } from 'react'
import {
    VStack,
    useColorMode,
    useDisclosure,
    useToast,
    Flex,
    Button
} from '@chakra-ui/react'
import {
    MessageInput,
    TypingIndicator,
} from '@chatscope/chat-ui-kit-react';
import Message from './Message';
import { AppContext } from '../../provider/AppProvider';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import { BITTENSOR_API_URL, BITTENSOR_URL, CHATGPT_URL, CONSUME_TOKEN_URL, GET_CHAT_REPONSE_URL, GET_USER_INFO_URL, IMAGE_GENERATE_URL } from '../../utils/urls';
import { ImageModal } from './ImageModal';

export default function Content() {
    const toast = useToast();
    const [cookies] = useCookies(['token']);
    const { user, setUser, currentAssistant, messages, setMessages, setFlag } = useContext(AppContext);
    const [model, setModel] = useState(user.model);
    const { colorMode } = useColorMode();
    const [isTyping, setIsTyping] = useState(false);
    const [src, setSrc] = useState('');

    const { isOpen, onOpen, onClose } = useDisclosure();

    useEffect(() => {
        const element = document.getElementById('messages');
        element.scrollTop = element.scrollHeight;
    }, [messages]);

    useEffect(() => {
        setModel(user.model);
    }, [user.model])

    const handleSendRequest = async (message) => {
        if (isTyping) return;
        const newMessage = {
            message,
            direction: 'outgoing',
            sender: "user",
            sentTime: new Date().toLocaleTimeString(),
            sentDate: new Date().toLocaleDateString(),
        };

        setMessages((prevMessages) => [...prevMessages, newMessage]);
        if (cookies.token === 'undefined') {
            return;
        }

        try {
            const res = await axios.post(GET_USER_INFO_URL, {message} ,{
                headers: {
                    Authorization: `Bearer ${cookies.token}`
                }
            });
            setUser({
                ...user,
                amount: res.data.token
            });
            if (!res.data.available) {
                return toast({
                    title: 'Error',
                    description: "You don't have enough token.",
                    status: 'error',
                    duration: '3000',
                    isClosable: true,
                    position: 'top-right'
                });
            }
            
            setIsTyping(true);
            if (currentAssistant.name === 'DALLE-3-Image Generation [PRO]') {
                setFlag(true)
                try {
                    const res = await axios.post(IMAGE_GENERATE_URL, {message}, {
                        headers: {
                            Authorization: `Bearer ${cookies.token}`
                        }
                    });

                    setSrc(res.data);
                    setFlag(false);
                    setIsTyping(false);
                    onOpen();
                } catch (error) {
                    setFlag(false);
                    setIsTyping(false);
                    console.log(error)
                }
                // generateImage(cookies.token, {message}, setSrc, handleNext);
                return ;
            }

            try {
                const response = await processMessageToChatGPT([...messages, newMessage]);
                const content = response.choices[0]?.message?.content;
                if (content) {
                    const chatGPTResponse = {
                        message: content,
                        sender: "ChatGPT",
                        sentTime: new Date().toLocaleTimeString(),
                        sentDate: new Date().toLocaleDateString(),
                    };
                    setMessages((prevMessages) => [...prevMessages, chatGPTResponse]);
                    try {
                        const res = await axios.post(CONSUME_TOKEN_URL, {message, content}, {
                            headers: {
                                Authorization: `Bearer ${cookies.token}`
                            }
                        });
                        setUser({
                            ...user,
                            amount: res.data
                        })
                    } catch (error) {
                        console.log(error);
                    }
                }
                setIsTyping(false);
            } catch (error) {
                setIsTyping(false);
                console.log(error);
            }
        } catch (error) {
            setIsTyping(false);
            console.log(error);
        }
    }

    async function processMessageToChatGPT(chatMessages) {
        const apiMessages = chatMessages.map((messageObject) => {
            const role = messageObject.sender === "ChatGPT" ? "assistant" : "user";
            return { role, content: messageObject.message };
        });

        let url, api_key, model1;
        switch(model) {
            case "ChatGPT-3.5":
                model1 = "gpt-3.5-turbo";
                url = CHATGPT_URL;
                api_key = "Bearer " + process.env.REACT_APP_API_KEY;
                break;
            case "ChatGPT-4.0":
                model1 = "gpt-4-turbo";
                url = CHATGPT_URL;
                api_key = "Bearer " + process.env.REACT_APP_API_KEY;
                break;
            case "ChatGPT-4o":
                model1 = "gpt-4o";
                url = CHATGPT_URL;
                api_key = "Bearer " + process.env.REACT_APP_API_KEY;
                break;
            case "Bittensor":
                url=BITTENSOR_URL;
                model1="cortext-ultra";
                api_key = process.env.REACT_APP_BITTENSOR_API_KEY;
                const response = await axios.post(BITTENSOR_API_URL, {
                    model: model1,
                    messages: [
                        { role: "system", content: currentAssistant.content },
                        ...apiMessages,
                    ],
                    temperature: currentAssistant.temperature === '' ? 0.8 : parseFloat(currentAssistant.temperature) ,
                    stream: false,
                    top_p: 1,
                    max_tokens: currentAssistant.max_tokens === '' ? 100 : parseInt(currentAssistant.max_tokens)
                })
                return {choices: [{
                    message: {
                        content: response.data[0].choices[0].delta.content
                    }
                }]};
            default:
                model1 = "gpt-3.5-turbo";
                url = CHATGPT_URL;
                api_key = "Bearer " + process.env.REACT_APP_API_KEY;
                break;
        }

        const apiRequestBody = {
            model: model1,
            messages: [
                { role: "system", content: currentAssistant.content },
                ...apiMessages,
            ],
            temperature: currentAssistant.temperature === '' ? 0.8 : parseFloat(currentAssistant.temperature) ,
            stream: false,
            top_p: 1,
            max_tokens: currentAssistant.max_tokens === '' ? 100 : parseInt(currentAssistant.max_tokens)
        };

        const response = await axios.post(GET_CHAT_REPONSE_URL, apiRequestBody);
        // const response = await fetch(url, {
        //     method: "POST",
        //     headers: {
        //         "Authorization": api_key,
        //         "Content-Type": "application/json",
        //         "Accept": 'application/json'
        //     },
        //     body: JSON.stringify(apiRequestBody),
        // });

        return response.data;
        
    }

    const handleQuestionClick = (ques) => {
        handleSendRequest(ques);
    }

    return (
        <VStack px={12} py={8}>
            <VStack id='messages' w={"100%"} h={"72vh"} maxHeight={"72vh"} overflow={'auto'}>
                {
                    messages.map((message, index) => {
                        return <Message 
                                key={'msg_'+index}
                                id={'msg_'+index}
                                time={message.sentTime}
                                date={message.sentDate}
                                mine={message.sender === 'user' ? true : false}
                                text={message.message}
                            />
                    })
                }
            </VStack>
            <VStack w={'100%'} position={'relative'} pt={2} style={{ alignItems: "start" }}>
                {isTyping && <TypingIndicator className='typing' content={'AI is typing...'} />}
                <MessageInput className={colorMode === 'light' ? 'msg-light' : 'msg-dark'} sendButton={false} attachButton={false} placeholder="Send a Message" onSend={handleSendRequest} />
                {
                    currentAssistant.enable_ques && <Flex justifyContent={'space-between'} w={'full'}>
                        {
                            currentAssistant.questions.map((item, index) => {
                                return <Button w={'31%'} borderRadius={50} justifyContent={'start'} pr={2} overflow={'hidden'} textOverflow={'ellipsis'} whiteSpace={'nowrap'} key={'question_'+index} onClick={() => handleQuestionClick(item)}>{item}</Button>
                            })
                        }
                    </Flex> 
                }
            </VStack>
            <ImageModal isOpen={isOpen} onClose={onClose} src={src}/>
        </VStack>
    )
}
