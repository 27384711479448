import React, { useContext, useEffect, useState } from 'react'
import {
    Flex,
    IconButton,
    Text,
    HStack,
    Menu,
    MenuButton,
    Avatar,
    VStack,
    Box,
    MenuList,
    MenuItem,
    MenuDivider,
    Image,
    Button,
    useColorModeValue,
    MenuOptionGroup,
    MenuItemOption,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverArrow,
    PopoverCloseButton,
    PopoverHeader,
    PopoverBody,
    Icon,
    useDisclosure
} from '@chakra-ui/react'
import {
    FiMenu,
    FiBell,
    FiChevronDown,
} from 'react-icons/fi'
import {
    IoAnalyticsSharp,
    IoLogoBitcoin,
    IoSearchSharp,
} from 'react-icons/io5';
import { FaAngleDown } from "react-icons/fa";
import ColorModeButton from './ColorModeButton';
import { Feature } from './Feature';
import Profile from '../Profile/Profile';
import Settings from '../Setting/Settings';
import { AppContext } from '../../provider/AppProvider';
import axios from 'axios';
import { CHANGE_USER_MODEL_URL, SERVER_URL } from '../../utils/urls';
import { useCookies } from 'react-cookie';
import { useDisconnect } from 'wagmi';
import { useNavigate } from 'react-router-dom';

export const MobileNav = ({ onOpen, ...rest }) => {
    const { user, setUser, currentAssistant } = useContext(AppContext);
    const {disconnect} = useDisconnect();
    const navigate = useNavigate();
    const [cookies, setCookie, removeCookie] = useCookies(['token']);
    const [model, setModel] = useState(user.model);
    const {isOpen: isProfileOpen, onOpen: onProfileOpen, onClose: onProfileClose} = useDisclosure();
    const {isOpen: isSettingOpen, onOpen: onSettingOpen, onClose: onSettingClose} = useDisclosure();

    const handleChangeModel = async (e) => {
        try {
            const response = await axios.post(CHANGE_USER_MODEL_URL, {model: e}, {
                headers: {
                    Authorization: `Bearer ${cookies.token}`
                }
            });

            const { success } = response.data;
            if (success) {
                setUser({
                    ...user,
                    model: e
                });
                setModel(e);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleSignOut = () => {
        removeCookie('token');
        disconnect();
        navigate('/login');
    }

    useEffect(() => {
        setModel(user.model);
    }, [user.model]);

    return (
        <Flex
            ml={{ base: 0, md: 60 }}
            px={{ base: 4, md: 4 }}
            height="20"
            alignItems="center"
            bg={useColorModeValue('white', 'gray.900')}
            borderBottomWidth="1px"
            borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
            justifyContent={'space-between'}
            {...rest}>
            <IconButton
                display={{ base: 'flex', md: 'none' }}
                onClick={onOpen}
                variant="outline"
                aria-label="open menu"
                icon={<FiMenu />}
            />
            <Menu>
                <MenuButton as={Button} rightIcon={<FaAngleDown />} variant='outline'>
                    {model}
                </MenuButton>
                <MenuList>
                    <MenuOptionGroup value={model} title='' type='radio' onChange={e => handleChangeModel(e)}>
                        <MenuItemOption value='ChatGPT-3.5'>ChatGPT-3.5</MenuItemOption>
                        <MenuItemOption value='ChatGPT-4.0'>ChatGPT-4.0</MenuItemOption>
                        <MenuItemOption value='ChatGPT-4o'>ChatGPT-4o</MenuItemOption>
                        <MenuItemOption value='Bittensor'>Bittensor</MenuItemOption>
                    </MenuOptionGroup>
                </MenuList>
            </Menu>

            {currentAssistant.name !== '' && <Flex alignItems={'center'} display={['none', {lg: 'flex'}]}>
                <Box w={"61px"} h={"61px"} display={'flex'} border={"1px solid #5DD8BE"} borderRadius={"50px"}>
                    <Image src={currentAssistant.img === '' ? SERVER_URL + '/public/img/6.webp' : SERVER_URL + currentAssistant.img} margin={'auto'} w={"50px"} h={"50px"} borderRadius={'50px'} />
                </Box>
                <Text pl={5} fontSize={"xl"}>{currentAssistant.name}</Text>
            </Flex>}

            <HStack spacing={{ base: '0', md: '4' }}>
                <ColorModeButton />
                {/* <Popover>
                    <PopoverTrigger>
                        <IconButton className='link' icon={<FiBell />} variant={'ghost'} />
                    </PopoverTrigger>
                    <PopoverContent textAlign={'left'}>
                        <PopoverArrow />
                        <PopoverCloseButton />
                        <PopoverHeader>News!</PopoverHeader>
                        <PopoverBody>
                            <Feature
                                icon={
                                    <Icon as={IoAnalyticsSharp} color={'yellow.500'} w={5} h={5} />
                                }
                                iconBg={useColorModeValue('yellow.100', 'yellow.900')}
                                text={'Business Planning'}
                            />
                            <Feature
                                 py={1}
                                icon={
                                    <Icon as={IoLogoBitcoin} color={'green.500'} w={5} h={5} />
                                }
                                iconBg={useColorModeValue('green.100', 'green.900')}
                                text={'Financial Planning'}
                            />
                            <Feature
                                py={1}
                                icon={
                                    <Icon as={IoSearchSharp} color={'purple.500'} w={5} h={5} />
                                }
                                iconBg={useColorModeValue('purple.100', 'purple.900')}
                                text={'Market Analysis'}
                            />
                        </PopoverBody>
                    </PopoverContent>
                </Popover> */}
                <Flex alignItems={'center'}>
                    <Menu>
                        <MenuButton py={2} transition="all 0.3s" _focus={{ boxShadow: 'none' }}>
                            <HStack>
                                <Image
                                    w={'50px'}
                                    borderRadius={'50%'}
                                    h={'50px'}
                                    src={
                                        user.avatar === '' ? '/avatar.jpg' : SERVER_URL + user.avatar
                                    }
                                />
                                <VStack
                                    display={{ base: 'none', md: 'flex' }}
                                    alignItems="flex-start"
                                    spacing="1px"
                                    ml="2">
                                    <Text fontSize="sm">{user.name}</Text>
                                    <Text fontSize="xs" color="gray.600">
                                        {user.amount}
                                    </Text>
                                </VStack>
                                <Box display={{ base: 'none', md: 'flex' }}>
                                    <FiChevronDown />
                                </Box>
                            </HStack>
                        </MenuButton>
                        <MenuList
                            // bg={useColorModeValue('white', 'gray.900')}
                            borderColor={useColorModeValue('gray.200', 'gray.700')}>
                            <MenuItem onClick={onProfileOpen}>Profile</MenuItem>
                            <MenuItem onClick={onSettingOpen}>Settings</MenuItem>
                            <MenuDivider />
                            <MenuItem onClick={handleSignOut}>
                                Sign out
                            </MenuItem>
                        </MenuList>
                    </Menu>
                </Flex>
            </HStack>
            <Profile isOpen={isProfileOpen} onClose={onProfileClose} />
            <Settings isOpen={isSettingOpen} onClose={onSettingClose}/>
        </Flex>
    )
}
