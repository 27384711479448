import React, { useContext, useEffect, useState } from 'react'
import {
    Card,
    Flex,
    Text,
    Button
} from '@chakra-ui/react'
import { RiDeleteBin6Line } from 'react-icons/ri';
import axios from 'axios';
import { DELETE_CHAT_HISTORY_URL, GET_ARCHIEVE_CHAT_HISTORY_LIST_URL } from '../../utils/urls';
import { useCookies } from 'react-cookie';
import { AppContext } from '../../provider/AppProvider';
import { useNavigate } from 'react-router-dom';
import { HiOutlineInboxStack } from "react-icons/hi2";

export default function ChatList({ onClose }) {
    const [cookies] = useCookies(['token']);
    const [chatList, setChatList] = useState([]);
    const { setCurrentAssistant, setMessages } = useContext(AppContext);
    const navigate = useNavigate();

    const getArchieveChatList = async () => {
        if (cookies.token === 'undefined') {
            return;
        }

        try {
            const response = await axios.get(GET_ARCHIEVE_CHAT_HISTORY_LIST_URL, {
                headers: {
                    Authorization: `Bearer ${cookies.token}`
                }
            });
            setChatList(response.data);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getArchieveChatList();
    }, []);

    const handleSelectChat = (val) => {
        setCurrentAssistant({
            name: val.name,
            img: val.img,
            content: val.content
        });
        setMessages(val.messages)
        onClose();
        navigate('/');
    }

    const handleDeleteChatItem = async (val) => {
        if (cookies.token === 'undefined') {
            return;
        }

        try {
            await axios.post(DELETE_CHAT_HISTORY_URL, { id: val }, {
                headers: {
                    Authorization: `Bearer ${cookies.token}`
                }
            });
            getArchieveChatList();
        } catch (error) {
            console.log(error);
        }
    }
    return (
        <div style={{width: '100%'}}>
            {
                chatList.map(item => {
                    return <Card my={2} key={item._id}>
                        <Flex justifyContent={'space-between'} alignItems={'center'} px={3} py={1} borderRadius={'md'}>
                            <Text onClick={() => handleSelectChat(item)} cursor={'pointer'}>{item.h_name}</Text>
                            <Button onClick={() => handleDeleteChatItem(item._id)}><RiDeleteBin6Line /></Button>
                        </Flex>
                    </Card>
                })
            }
            {
                chatList.length === 0 && <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}><HiOutlineInboxStack />&nbsp;No Data</div>
            }
        </div>
    )
}
