import { jwtDecode } from 'jwt-decode';
import {createContext, useState, useEffect} from 'react';
import axios from 'axios';
import { GET_USER_INFO_URL } from '../utils/urls';
import { useCookies } from 'react-cookie';
import { useDisconnect } from 'wagmi';

export const AppContext = createContext();

const AppProvider = ({ children }) => {
    const [cookies, removeCookie] = useCookies(['token']);
    const { disconnect } = useDisconnect();
    const [currentAssistant, setCurrentAssistant] = useState({
        name: '',
        img: '',
        content: '',
        temperature: '',
        max_tokens: '',
        enable_ques: false,
        questions: []
    })
    const [user, setUser] = useState({
        name: '',
        email: '',
        amount: 0,
        lang: '',
        model: '',
        subscription: {},
        avatar: '',
        admin: false
    });
    const [messages, setMessages] = useState([
        {
            message: `Hello! Ask me anything!`,
            sentTime: new Date().toLocaleTimeString(),
            sentDate: new Date().toLocaleDateString(),
            sender: "ChatGPT",
        },
    ]);
    const [flag, setFlag] = useState(false);
    const [a_flag, setA_Flag] = useState(false);
    const getUserInfo = async () => {
        if (cookies.token === 'undefined') {
            return;
        }

        try {
          const response = await axios.post(GET_USER_INFO_URL, {message: ''}, {
            headers: {
              Authorization: `Bearer ${cookies.token}`
            }
          });
    
          const {wallet, name, token, model, language, subscription, email, avatar, admin} = response.data;
          setUser({
            ...user,
            wallet,
            name,
            amount: token,
            model,
            lang: language,
            subscription,
            email,
            avatar,
            admin
          })
        } catch (error) {
          console.log(error);
        }
    }
    useEffect(() => {
        if (cookies.token !== "undefined" && cookies.token !== undefined) {
            const decode = jwtDecode(cookies.token);

            if (decode.exp < new Date().getTime() / 1000) {
                removeCookie('token');
                disconnect();
            }
            else {
                getUserInfo()
            }
        }
        else {
            removeCookie('token');
            disconnect();
        }
    }, []);
    return (
        <AppContext.Provider
            value={{
                user,
                setUser,
                currentAssistant,
                setCurrentAssistant,
                messages,
                setMessages,
                flag,
                setFlag,
                a_flag,
                setA_Flag
            }}
        >
            {
                children
            }
        </AppContext.Provider>
    )
}

export default AppProvider;