import React, { useContext, useEffect } from 'react'
import {
  Box,
  Drawer,
  DrawerContent,
  useColorModeValue,
  useDisclosure
} from '@chakra-ui/react'
import { SidebarContent } from './Sidebar/SidebarContent'
import { MobileNav } from './Sidebar/MobileNav'
import Content from './Content/Content'
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom'
import { useDisconnect } from 'wagmi'
import { AppContext } from '../provider/AppProvider'

export default function Home() {
  const {disconnect} = useDisconnect();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [cookies, setCookie, removeCookie] = useCookies(['token']);

  useEffect(() => {
    if (cookies.token === "undefined" || cookies.token === null) {
      disconnect();
      removeCookie('token');
      navigate('/login');
    }
  }, [cookies.token]);

  return (
    <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')}>
      <SidebarContent onClose={() => onClose} display={{ base: 'none', md: 'block' }} />
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full">
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav onOpen={onOpen} />
      <Box ml={{ base: 0, md: 60 }} p="4">
        <Content />
      </Box>
    </Box>
  )
}
