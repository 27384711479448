import React, { useState } from 'react'
import {
    Flex,
    Center,
    VStack,
} from '@chakra-ui/react'
import SignIn from './Sign/SignIn'
import SignUp from './Sign/SignUp'

export default function Login() {
    const [sign, setSign] = useState(true);

    const changeSign = (val) => {
        setSign(val);
    }

    return (
        <div style={{ width: '100%', height: '100vh' }}>
            <Flex w={'100%'} h={'100%'} backgroundImage={'linear-gradient(197.91deg, #1e2730 -78.825%, #101516 92.621%)'} backgroundRepeat={'no-repeat'} backgroundSize={'cover'} display={['none', { md: 'flex' }]}>
                <Center w={'100%'}>
                    {sign && <SignIn change={changeSign} isMobile={'false'}/>}
                    {!sign && <SignUp change={changeSign} isMobile={'false'} />}
                </Center>
            </Flex>
            <Flex w={'100%'} h={'100%'} backgroundImage={'linear-gradient(197.91deg, #1e2730 -78.825%, #101516 92.621%)'} backgroundRepeat={'no-repeat'} backgroundSize={'cover'} display={['flex', { md: 'none' }]}>
                <Center w={'100%'}>
                    <VStack>
                    {sign && <SignIn change={changeSign} isMobile={'true'}/>}
                    {!sign && <SignUp change={changeSign} isMobile={'true'}/>}
                        {/* <ConnectButton label='Login with Wallet' /> */}
                    </VStack>
                </Center>
            </Flex>
        </div>
    )
}
