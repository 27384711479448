import React, { useContext, useState } from 'react'
import {
    Stack,
    Heading,
    FormControl,
    FormLabel,
    Center,
    Avatar,
    Button,
    Input,
    useColorModeValue,
    useToast,
    AvatarBadge,
    IconButton
} from '@chakra-ui/react'
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { CHANGE_USER_INFO_URL } from '../../utils/urls';
import { AppContext } from '../../provider/AppProvider';
import { FileUpload } from 'primereact/fileupload'
import { SmallCloseIcon } from '@chakra-ui/icons';

export default function ProfileInfo() {
    const {user, setUser} = useContext(AppContext);
    const toast = useToast();
    const [cookies] = useCookies(['token']);
    const [data, setData] = useState({
        name: '',
        email: '',
        password: '',
        wallet: '',
        img: null
    });

    const handleSaveUserInfo = async () => {
        if (data.name === '' && data.email === '' && data.password === '' && data.wallet === '' && data.img === null) {
            return;
        }

        try {
            const formData = new FormData();
            formData.append('file', data.img);
            formData.append('data', JSON.stringify(data));

            const response = await axios.post(CHANGE_USER_INFO_URL, formData, {
                headers: {
                    Authorization: `Bearer ${cookies.token}`
                }
            })
            const {success} = response.data;
            if (success) {
                toast({
                    title: 'Success!',
                    status: 'success',
                    isClosable: true,
                    position: 'top-right'
                })
                    setUser({
                        ...user,
                        name: data.name !== '' ? data.name : user.name,
                        avatar: data.img === null ? user.img : response.data.data.avatar
                    })
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleChangeFile = (e) => {
        setData({
            ...data,
            img: e.files[0]
        })
        console.log(e)
    }

    return (
            <Stack
                spacing={4}
                w={'full'}
                maxW={'md'}
                bg={useColorModeValue('white', 'gray.700')}
                rounded={'xl'}
                p={6}>
                <Heading lineHeight={1.1} fontSize={{ base: '2xl', sm: '3xl' }}>
                    User Profile Edit
                </Heading>
                <FormControl id="userName">
                    <FormLabel>User Icon</FormLabel>
                    <Stack direction={['column', 'row']} spacing={6}>
                        <Center>
                            <Avatar size="xl" src={data.img === null ? '/avatar.jpg' : data.img.objectURL}>
                                {data.img !== null && <AvatarBadge
                                    as={IconButton}
                                    size="sm"
                                    rounded="full"
                                    top="-10px"
                                    colorScheme="red"
                                    aria-label="remove Image"
                                    icon={<SmallCloseIcon />}
                                    onClick={() => setData({...data, img: null})}
                                />}
                            </Avatar>
                        </Center>
                        <Center w="full">
                            {data.img === null && <FileUpload mode="basic" chooseLabel='Change Icon' name="demo[]" accept="image/*" maxFileSize={10000000} onSelect={e => handleChangeFile(e)} />}
                        </Center>
                    </Stack>
                </FormControl>
                <FormControl id="userName">
                    <FormLabel>User name</FormLabel>
                    <Input
                        placeholder="UserName"
                        _placeholder={{ color: 'gray.500' }}
                        type="text"
                        value={data.name}
                        onChange={e => setData({...data, name: e.target.value})}
                    />
                </FormControl>
                <FormControl id="email">
                    <FormLabel>Email address</FormLabel>
                    <Input
                        placeholder="your-email@example.com"
                        _placeholder={{ color: 'gray.500' }}
                        type="email"
                        value={data.email}
                        onChange={e => setData({...data, email: e.target.value})}
                    />
                </FormControl>
                <FormControl id="wallet">
                    <FormLabel>Wallet address</FormLabel>
                    <Input
                        placeholder="0x00000000000000000000000000000000000"
                        _placeholder={{ color: 'gray.500' }}
                        type="email"
                        value={data.wallet}
                        onChange={e => setData({...data, wallet: e.target.value})}
                    />
                </FormControl>
                <FormControl id="password">
                    <FormLabel>Password</FormLabel>
                    <Input
                        placeholder="password"
                        _placeholder={{ color: 'gray.500' }}
                        type="password"
                        value={data.password}
                        onChange={e => setData({...data, password: e.target.value})}
                    />
                </FormControl>
                <Stack spacing={6} direction={['column', 'row']}>
                    <Button
                        bg={'blue.400'}
                        color={'white'}
                        _hover={{
                            bg: 'blue.500',
                        }}
                        onClick={handleSaveUserInfo}
                    >
                        Save
                    </Button>
                </Stack>
            </Stack>
    )
}
