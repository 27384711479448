import React from 'react'
import PriceWrapper from './PriceWrapper'
import {
    VStack,
    Heading,
    Stack,
    Wrap,
    Box,
    HStack,
    Text,
    List,
    ListItem,
    ListIcon,
    Button,
    useColorModeValue
} from '@chakra-ui/react';
import { FaCheckCircle } from 'react-icons/fa';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { CHECKOUT_SUBSCRIPTION_URL, CHECKOUT_TOKEN_URL } from '../../utils/urls';

export default function BuyContent() {
    const [cookies] = useCookies(['token']);

    const handleBuySubscription = async (val) => {
        if (cookies.token === 'undefined') {
            return;
        }

        try {
            const response = await axios.post(CHECKOUT_SUBSCRIPTION_URL, { amount: val.toString(), currency: 'USD' }, {
                headers: {
                    Authorization: `Bearer ${cookies.token}`
                }
            });

            const { result } = response.data;

            let element = document.createElement('a');
            element.setAttribute('target', '_blank');
            element.setAttribute('href', result.url);
            element.click();
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div>
            <VStack spacing={2} textAlign="center">
                <Heading as="h1" fontSize="4xl">
                    Subscription
                </Heading>
                {/* <Text fontSize="lg" color={'white'}>
                        Start with 14-day free trial. No credit card needed. Cancel at
                        anytime.
                    </Text> */}
            </VStack>
            {/* <Stack
                width={"100%"}
                direction={{ base: 'column', md: 'row' }}
                textAlign="center"
                justify="center"
                spacing={{ base: 4, lg: 10 }}
                py={10}>
                <PriceWrapper>
                    <Box py={4} px={12}>

                        <Text fontWeight="800" fontSize="4xl">
                            1 Month
                        </Text>
                        <HStack justifyContent="center">
                            <Text fontSize="3xl" fontWeight="600">
                                $
                            </Text>
                            <Text fontSize="5xl" fontWeight="900">
                                9.99
                            </Text>
                        </HStack>
                    </Box>
                    <VStack
                        bg={useColorModeValue('gray.50', 'gray.700')}
                        py={4}
                        borderBottomRadius={'xl'}>
                        <List spacing={3} textAlign="start" px={12}>
                            <ListItem>
                                <ListIcon as={FaCheckCircle} color="green.500" />
                                100,000 tokens per day
                            </ListItem>
                            <ListItem>
                                <ListIcon as={FaCheckCircle} color="green.500" />
                                ⚡️ Instant replies
                            </ListItem>
                            <ListItem>
                                <ListIcon as={FaCheckCircle} color="green.500" />
                                PRO chat modes
                            </ListItem>
                        </List>
                        <Box w="80%" pt={7}>
                            <Button w="full" colorScheme="red" onClick={() => handleBuySubscription(9.99)}>
                                Buy Now
                            </Button>
                        </Box>
                    </VStack>
                </PriceWrapper>

                <PriceWrapper>
                    <Box position="relative">
                        <Box
                            position="absolute"
                            top="-16px"
                            left="50%"
                            style={{ transform: 'translate(-50%)' }}>
                        </Box>
                        <Box py={4} px={12}>
                            <Text fontWeight="500" fontSize="2xl">
                                3 Month
                            </Text>
                            <HStack justifyContent="center">
                                <Text fontSize="3xl" fontWeight="600">
                                    $
                                </Text>
                                <Text fontSize="5xl" fontWeight="900">
                                    24.99
                                </Text>
                            </HStack>
                        </Box>
                        <VStack
                            bg={useColorModeValue('gray.50', 'gray.700')}
                            py={4}
                            borderBottomRadius={'xl'}>
                            <List spacing={3} textAlign="start" px={12}>
                                <ListItem>
                                    <ListIcon as={FaCheckCircle} color="green.500" />
                                    100,000 tokens per day
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={FaCheckCircle} color="green.500" />
                                    ⚡️ Instant replies
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={FaCheckCircle} color="green.500" />
                                    PRO chat modes
                                </ListItem>
                            </List>
                            <Box w="80%" pt={7}>
                                <Button w="full" colorScheme="red" variant="outline" onClick={() => handleBuySubscription(24.99)}>
                                    Buy Now
                                </Button>
                            </Box>
                        </VStack>
                    </Box>
                </PriceWrapper>

                <PriceWrapper>
                    <Box py={4} px={12}>
                        <Text fontWeight="500" fontSize="2xl">
                            6 Month
                        </Text>
                        <HStack justifyContent="center">
                            <Text fontSize="3xl" fontWeight="600">
                                $
                            </Text>
                            <Text fontSize="5xl" fontWeight="900">
                                49.99
                            </Text>
                        </HStack>
                    </Box>
                    <VStack
                        bg={useColorModeValue('gray.50', 'gray.700')}
                        py={4}
                        borderBottomRadius={'xl'}>
                        <List spacing={3} textAlign="start" px={12}>
                            <ListItem>
                                <ListIcon as={FaCheckCircle} color="green.500" />
                                100,000 tokens per day
                            </ListItem>
                            <ListItem>
                                <ListIcon as={FaCheckCircle} color="green.500" />
                                ⚡️ Instant replies
                            </ListItem>
                            <ListItem>
                                <ListIcon as={FaCheckCircle} color="green.500" />
                                PRO chat modes
                            </ListItem>
                        </List>
                        <Box w="80%" pt={7}>
                            <Button w="full" colorScheme="red" variant="outline" onClick={() => handleBuySubscription(49.99)}>
                                Buy Now
                            </Button>
                        </Box>
                    </VStack>
                </PriceWrapper>
                <PriceWrapper>
                    <Box py={4} px={12}>
                        <Text fontWeight="500" fontSize="2xl">
                            12 Month
                        </Text>
                        <HStack justifyContent="center">
                            <Text fontSize="3xl" fontWeight="600">
                                $
                            </Text>
                            <Text fontSize="5xl" fontWeight="900">
                                89.99
                            </Text>
                        </HStack>
                    </Box>
                    <VStack
                        bg={useColorModeValue('gray.50', 'gray.700')}
                        py={4}
                        borderBottomRadius={'xl'}>
                        <List spacing={3} textAlign="start" px={12}>
                            <ListItem>
                                <ListIcon as={FaCheckCircle} color="green.500" />
                                100,000 tokens per day
                            </ListItem>
                            <ListItem>
                                <ListIcon as={FaCheckCircle} color="green.500" />
                                ⚡️ Instant replies
                            </ListItem>
                            <ListItem>
                                <ListIcon as={FaCheckCircle} color="green.500" />
                                PRO chat modes
                            </ListItem>
                        </List>
                        <Box w="80%" pt={7}>
                            <Button w="full" colorScheme="red" variant="outline" onClick={() => handleBuySubscription(89.99)}>
                                Buy Now
                            </Button>
                        </Box>
                    </VStack>
                </PriceWrapper>
            </Stack> */}
            <Stack
                width={"100%"}
                direction={{ base: 'column', md: 'row' }}
                textAlign="center"
                justify="center"
                spacing={{ base: 4, lg: 10 }}
                py={10}>
                <PriceWrapper>
                    <div className='pricing-column-wrapper' style={{height: 'full'}}>
                        <div className="pricing-column" style={{height: 'full'}}>
                            <div className="pricing-price-row">
                                <div className="pricing-price-wrapper">
                                    <div className="pricing-price">
                                        <div className="pricing-cost">$9.99</div>
                                    </div>
                                </div>
                            </div>
                            <div className="pricing-row-title">
                                <div className="pricing_row_title">1 Month</div>
                            </div>
                            <List className="pricing-row" spacing={3} textAlign="start" px={12}>
                                <ListItem>
                                    <ListIcon as={FaCheckCircle} color="green.500" />
                                    🔋 85,000 tokens daily
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={FaCheckCircle} color="green.500" />
                                    ⚡️ Immediate responses
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={FaCheckCircle} color="green.500" />
                                    💎 Advanced chat modes
                                </ListItem>
                            </List>
                            <div className="pricing-footer">
                                <div className="gem-button-container gem-button-position-center">
                                    <Button className='gem-button gem-green' onClick={() => handleBuySubscription(9.99)}>Buy Now</Button>
                                    {/* <a href="#" className="gem-button gem-green">order now</a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </PriceWrapper>
                <PriceWrapper>
                    <div className='pricing-column-wrapper-purpel' style={{height: 'full'}}>
                        <div className="pricing-column" style={{height: 'full'}}>
                            <div className="pricing-price-row">
                                <div className="pricing-price-wrapper">
                                    <div className="pricing-price">
                                        <div className="pricing-cost">$24.99</div>
                                    </div>
                                </div>
                            </div>
                            <div className="pricing-row-title">
                                <div className="pricing_row_title">3 Month</div>
                            </div>
                            <List className="pricing-row" spacing={3} textAlign="start" px={12}>
                                <ListItem>
                                    <ListIcon as={FaCheckCircle} color="green.500" />
                                    🔋 85,000 tokens daily
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={FaCheckCircle} color="green.500" />
                                    ⚡️ Immediate responses
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={FaCheckCircle} color="green.500" />
                                    💎 Advanced chat modes
                                </ListItem>
                            </List>
                            <div className="pricing-footer">
                                <div className="gem-button-container gem-button-position-center">
                                    <Button className='gem-button gem-purpel' onClick={() => handleBuySubscription(24.99)}>Buy Now</Button>
                                    {/* <a href="#" className="gem-button gem-green">order now</a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </PriceWrapper>
                <PriceWrapper>
                    <div className='pricing-column-wrapper-orange' style={{height: 'full'}}>
                        <div className="pricing-column" style={{height: 'full'}}>
                            <div className="pricing-price-row">
                                <div className="pricing-price-wrapper">
                                    <div className="pricing-price">
                                        <div className="pricing-cost">$49.99</div>
                                    </div>
                                </div>
                            </div>
                            <div className="pricing-row-title">
                                <div className="pricing_row_title">6 Month</div>
                            </div>
                            <List className="pricing-row" spacing={3} textAlign="start" px={12}>
                                <ListItem>
                                    <ListIcon as={FaCheckCircle} color="green.500" />
                                    🔋 85,000 tokens daily
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={FaCheckCircle} color="green.500" />
                                    ⚡️ Immediate responses
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={FaCheckCircle} color="green.500" />
                                    💎 Advanced chat modes
                                </ListItem>
                            </List>
                            <div className="pricing-footer">
                                <div className="gem-button-container gem-button-position-center">
                                    <Button className='gem-button gem-orange' onClick={() => handleBuySubscription(49.99)}>Buy Now</Button>
                                    {/* <a href="#" className="gem-button gem-green">order now</a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </PriceWrapper>
                <PriceWrapper>
                    <div className='pricing-column-wrapper-yellow' style={{height: 'full'}}>
                        <div className="pricing-column" style={{height: 'full'}}>
                            <div className="pricing-price-row">
                                <div className="pricing-price-wrapper">
                                    <div className="pricing-price">
                                        <div className="pricing-cost">$89.99</div>
                                    </div>
                                </div>
                            </div>
                            <div className="pricing-row-title">
                                <div className="pricing_row_title">12 Month</div>
                            </div>
                            <List className="pricing-row" spacing={3} textAlign="start" px={12}>
                                <ListItem>
                                    <ListIcon as={FaCheckCircle} color="green.500" />
                                    🔋 85,000 tokens daily
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={FaCheckCircle} color="green.500" />
                                    ⚡️ Immediate responses
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={FaCheckCircle} color="green.500" />
                                    💎 Advanced chat modes
                                </ListItem>
                            </List>
                            <div className="pricing-footer">
                                <div className="gem-button-container gem-button-position-center">
                                    <Button className='gem-button gem-yellow' onClick={() => handleBuySubscription(89.99)}>Buy Now</Button>
                                    {/* <a href="#" className="gem-button gem-green">order now</a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </PriceWrapper>
            </Stack>
        </div>
    )
}
