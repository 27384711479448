import React, { useEffect, useState } from 'react'
import AssistantItem from './AssistantItem';
import {
    Wrap,
    Heading,
    VStack,
    Text,
    Card,
    CardBody,
    Center,
    useDisclosure
} from '@chakra-ui/react';
import axios from 'axios'
import { GET_ASSISTANTS_URL } from '../../utils/urls';
import { useCookies } from 'react-cookie';
import { GoPlus } from "react-icons/go";
import CreateAssistant from './CreateAssistant';

export default function AssistantContent() {
    const [cookies] = useCookies(['token']);
    const [assistants, setAssistants] = useState([]);
    const [myAssistants, setMyAssistants] = useState([]);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const getAssistantsList = async () => {
        if (cookies.token === undefined) {
            return;
        }
        try {
            const response = await axios.get(GET_ASSISTANTS_URL, {
                headers: {
                    Authorization: `Bearer ${cookies.token}`
                }
            })
            const {data, userData} = response.data;
            setAssistants(data);
            setMyAssistants(userData);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getAssistantsList();
    }, [isOpen]);
  return (
    <VStack alignItems={'start'}>
        <Heading mb={4}>My Assistants</Heading>
            <Card w={'10rem'} h={'12rem'} borderRadius={30} border={'dashed 2px'} cursor={'pointer'} onClick={onOpen}>
                <CardBody w={'10rem'} h={'8.5rem'}>
                    <Center h={'full'}>
                        <VStack>
                            <GoPlus fontSize={'3rem'} />
                            <Text>
                                Create Assistant
                            </Text>
                        </VStack>
                    </Center>
                </CardBody>
            </Card>
        <Wrap spacing={'1rem'}>
            {
                myAssistants.map(item => {
                    return <AssistantItem key={item._id}  mine={true} item={item} get={getAssistantsList} />
                })
            }
        </Wrap>
        <Heading my={4}>Assistants</Heading>
        <Wrap spacing={'1rem'}>
            {
                assistants.map(item => {
                    return <AssistantItem key={item._id}  mine={false} item={item} get={getAssistantsList}/>
                })
            }
        </Wrap>
        <CreateAssistant isOpen={isOpen} onClose={onClose} />
    </VStack>
  )
}
