import React from 'react'
import {
    Box,
    Flex,
    Icon
} from "@chakra-ui/react"

export const NavItem = ({ icon, children, onClick, ...rest }) => {
    return (
        <Box
            as="a"
            href="#"
            style={{ textDecoration: 'none' }}
            _focus={{ boxShadow: 'none' }}
            onClick={onClick}>
            <Flex
                align="center"
                p="2"
                mx="4"
                borderRadius="lg"
                role="group"
                cursor="pointer"
                border={"solid 1px transparent"}
                _hover={{
                    // bg: 'cyan.400',
                    border: "solid 1px",
                    // color: 'white',
                }}
                {...rest}>
                {icon && (
                    <Icon
                        mr="4"
                        fontSize="16"
                        _groupHover={{
                            // color: 'white',
                        }}
                        as={icon}
                    />
                )}
                {children}
            </Flex>
        </Box>
    )
}